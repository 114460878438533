import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      height: '85px',
      border: '1px solid #ADADAD',
      borderTop: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        '& > $helpIcon': {
          color: theme.palette.secondary.main,
        },
        '& > $helpLink': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '&:focus': {
        backgroundColor: theme.palette.grey[100],
        '& > $helpIcon': {
          color: theme.palette.secondary.main,
        },
        '& > $helpLink': {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    helpIcon: {
      marginTop: '24px',
      marginLeft: '20px',
    },
    text: {
      marginTop: '15px',
      marginLeft: '-35px',
    },
    helpLink: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'ds-app-HelpMenuItem' },
);
