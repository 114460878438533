import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import useStyles from './useStyles';
import MobileStepper from '../MobileStepper';
import { toSentenceCase, useMobilePageName } from './management';

const MobileAppBarNew: React.FC = () => {
  const classes = useStyles()();
  const { moduleName, pageName } = useMobilePageName();
  const mappedModuleName = toSentenceCase(moduleName);
  const mappedPageName = toSentenceCase(pageName);
  const moduleOnly =
    mappedModuleName?.toLowerCase() === mappedPageName?.toLowerCase();
  return (
    <Grid
      item
      xs={12}
      className={classes.appBarRoot}
      container
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <AppBar position="relative" className={classes.root}>
        <MobileStepper />
      </AppBar>
      {moduleOnly ? (
        <DisplayText className={classes.currentStep} variant="h4">
          {mappedModuleName}
        </DisplayText>
      ) : (
        <DisplayText className={classes.currentStep} variant="h4">
          {mappedModuleName}
          <span className={classes.pageName}>/{mappedPageName}</span>
        </DisplayText>
      )}
    </Grid>
  );
};

export default MobileAppBarNew;
