import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    callCenterBox: {
      minHeight: '210px',
    },
    fixed: {
      [theme.breakpoints.up('lg')]: {
        position: 'fixed',
        top: '25px',
        right: '0px',
      },
    },
    locked: {
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        overflow: 'hidden',
        right: '0px',
      },
    },
    cardStyling: {
      paddingRight: '20px',
      paddingBottom: '20px',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '18px',
        paddingTop: '25px',
      },
    },
  }),

  { name: 'ds-app-CallCenterBoxControl' },
);
