import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        zIndex: 1000,
        position: 'relative',
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '@media print': {
        display: 'none',
      },
    },
  }),
  { name: 'ds-app-ProgressStepper' },
);
