import makeStyles from '@mui/styles/makeStyles';

export const size = 38;

export default makeStyles(
  (theme) => ({
    outerCircle: {
      marginLeft: `-${size}px`,
    },
    percentageTextSize: {
      fontSize: '0.875rem',
    },
    percentageSignSize: {
      fontSize: '0.625rem',
    },
    hideStepConnectorColor: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '100%',
    },
  }),
  { name: 'ds-app-CircularProgress' },
);
