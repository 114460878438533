import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    stepIcon: {
      marginLeft: '19px',
      marginRight: '15px',
    },
  }),
  { name: 'ds-app-StepIcon' },
);
