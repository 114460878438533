import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import noop from 'lodash/noop';
import { useEffect, useMemo, useState } from 'react';
import useStyles from './useStyles';

export interface DefaultSpinnerProps {
  message?: React.ReactNode | string[];
  isLoading?: boolean;
}

/**
 * The purpose of this component is to act as a progress symbol for whenever
 * the application is either making async calls or when it is initially starting up
 * @param message: array of Messages displayed on the loading spinner
 * @param isLoading: This determines whether or not the spinner is displayed.
 */
const DefaultSpinner: React.FC<DefaultSpinnerProps> = ({
  message,
  isLoading,
}) => {
  const [spinnerIndex, setSpinnerIndex] = useState(0);
  useEffect(() => {
    if (isLoading && Array.isArray(message)) {
      const messageInterval = setInterval(() => {
        setSpinnerIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (message && nextIndex >= message?.length) {
            return 0;
          }
          return nextIndex;
        });
      }, 4000);
      return () => {
        clearInterval(messageInterval);
      };
    }
    return noop;
  }, [isLoading, message]);

  const fadeStyle = useMemo(
    () => ({
      transitionDelay: isLoading ? '800ms' : '0ms',
    }),
    [isLoading],
  );

  const classes = useStyles();
  const bottomProgressClass = useMemo(
    () => ({
      circleIndeterminate: classes.spinnerWidth,
    }),
    [classes.spinnerWidth],
  );

  return (
    <Fade in={isLoading} unmountOnExit style={fadeStyle}>
      <Backdrop open className={classes.backdrop}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.spinnerBackground} />
            <div className={classes.root}>
              <div className={classes.box}>
                <CircularProgress
                  className={classes.top}
                  variant="determinate"
                  thickness={4}
                  value={100}
                  size={80}
                />
                <CircularProgress
                  classes={bottomProgressClass}
                  className={classes.bottom}
                  variant="indeterminate"
                  thickness={4}
                  value={25}
                  size={80}
                  disableShrink
                />
                <div className={classes.headingMain}>
                  {Array.isArray(message) ? message[spinnerIndex] : message}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Backdrop>
    </Fade>
  );
};

export default DefaultSpinner;
