import Grid from '@mui/material/Grid';
import cn from 'classnames';
import {
  useDisplayCreditBasedScoringLink,
  useDisplayHeaderFooter,
} from '../../app/ContextAPI';
import type { ResourceConfig } from '../../app/types';
import ResourceLink from '../ResourceLink';
import useStyles from './useStyles';

declare let CONFIG: ResourceConfig;
const companyName = CONFIG.companyNameFooter;

const Footer: React.FC = () => {
  const displayCreditBasedScoringLink = useDisplayCreditBasedScoringLink();
  const displayHeaderFooter = useDisplayHeaderFooter();
  const gridItemSize = displayCreditBasedScoringLink ? 3 : 4;
  const classes = useStyles();
  const year = new Date().getFullYear();

  return displayHeaderFooter ? (
    <Grid container item className={classes.root} alignContent="flex-end">
      <Grid container item className={classes.containerColor}>
        <div
          className={
            displayCreditBasedScoringLink
              ? classes.containerWide
              : classes.container
          }
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            xs={12}
          >
            <Grid
              item
              container
              xs={12}
              md={gridItemSize}
              className={classes.mobileSpacing}
              alignItems="center"
              justifyContent="center"
            >
              <ResourceLink hrefKey="privacySecurity">
                Privacy and Security
              </ResourceLink>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={gridItemSize}
              className={classes.mobileSpacing}
              alignItems="center"
              justifyContent="center"
            >
              <ResourceLink hrefKey="insurancePractices">
                Insurance Practices
              </ResourceLink>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={gridItemSize}
              className={cn(classes.linkMargin, classes.mobileSpacing)}
              alignItems="center"
              justifyContent="center"
            >
              <ResourceLink hrefKey="webFeedback">Web Feedback</ResourceLink>
            </Grid>
            {displayCreditBasedScoringLink && (
              <Grid
                item
                container
                xs={12}
                md={gridItemSize}
                className={classes.mobileSpacing}
                alignItems="center"
                justifyContent="center"
              >
                <ResourceLink hrefKey="creditBasedInsurance">
                  Credit Based Insurance Scoring
                </ResourceLink>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item className={classes.copyright}>
              © {year} {companyName} All rights reserved.
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  ) : null;
};

export default Footer;
