import { CALL_CENTER_INFO } from '@amfament/digitalsales-lib-common/CallCenterBox';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import { IconVariant } from '@amfament/digitalsales-lib-coreuicomps/Icon';
import { useProductQuoteIds } from '../../app/ContextAPI';
import type { ResourceConfig } from '../../app/types';
import HelpMenuItem from './HelpMenuItem';
import ProductQuoteIds from './ProductQuoteIds';
import useStyles from './useStyles';

declare let CONFIG: ResourceConfig;
const NEED_HELP_OPTIONS = [
  {
    id: 1,
    value: 'Call',
    label: 'Give us a call.',
    link: CALL_CENTER_INFO.NumberFormatted,
    icon: IconVariant.call,
    linkRef: CALL_CENTER_INFO.NumberLink,
  },
  {
    id: 2,
    value: 'Answers',
    label: 'The answers you need.',
    link: 'Visit our FAQ',
    icon: IconVariant.faqs,
    linkRef: CONFIG.faq.baseURL,
  },
];

/**
 * Component that creates a helpmenu with two options
 */
const HelpMenu: React.FC = () => {
  const [menuItemHighlighted, setMenuItemHighlighted] = useState(false);
  const classes = useStyles();

  // Handles case when user leaves HelpMenu div
  const handleMouseLeave = useCallback(() => setMenuItemHighlighted(false), []);
  const handleMouseOver = useCallback(
    (item: number) =>
      item === 1 ? setMenuItemHighlighted(true) : setMenuItemHighlighted(false),
    [],
  );

  const productQuoteIds = useProductQuoteIds();
  const altLink = productQuoteIds.find((quote) => quote.altNumberLink);

  return (
    <div className={classes.root} onMouseLeave={handleMouseLeave}>
      <div
        className={cn(classes.arrow, {
          [classes.grayArrow]: menuItemHighlighted,
        })}
      />
      {NEED_HELP_OPTIONS.map((item, index) => (
        <HelpMenuItem
          key={item.id}
          menutItemKey={item.id}
          icon={item.icon}
          text={item.label}
          link={
            altLink && index === 0 ? altLink.altNumberFormatted! : item.link
          }
          linkRef={
            altLink && index === 0 ? altLink.altNumberLink! : item.linkRef
          }
          onMouseOver={handleMouseOver}
        />
      ))}
      <ProductQuoteIds productQuoteIds={productQuoteIds} />
    </div>
  );
};

export default HelpMenu;
