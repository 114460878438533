import makeStyles from '@mui/styles/makeStyles';
import { useFullPageDisplay } from '../../app/ContextAPI';

export default () => {
  const fullPageDisplay = useFullPageDisplay();
  return makeStyles(
    (theme) => ({
      gradient: {
        position: 'absolute',
        zIndex: '-100',
        backgroundImage: `linear-gradient(${theme.palette.primary[100]} 13%, ${theme.palette.common.white} 26%)`,
        top: '123px',
        minHeight: '100%',
        [theme.breakpoints.down('md')]: {
          top: fullPageDisplay ? '60px' : '123px',
        },
      },
      root: {
        minHeight: 'calc(100% - 352px)',
        [theme.breakpoints.down('lg')]: {
          minHeight: 'calc(100% - 585px)',
        },
      },
      rootContainer: {
        paddingTop: theme.pagePadding.contentPaddingTop,
        paddingBottom: theme.pagePadding.contentPaddingBottom,
        [theme.breakpoints.down('lg')]: {
          paddingTop: '0px',
        },
      },
      mobileAppBarDisabled: {
        paddingTop: theme.pagePadding.contentPaddingTopMobile,
        paddingBottom: theme.pagePadding.contentPaddingBottomMobile,
      },
    }),
    { name: 'ds-app-Page' },
  );
};
