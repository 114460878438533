import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      background: 'transparent',
      width: '100%',
      padding: '14px',
      paddingBottom: '0px',
    },
    stepPage: {
      color: theme.palette.text.secondary,
      '& .MuiStepLabel-alternativeLabel': {
        marginTop: '0px',
      },
    },
  }),
  { name: 'ds-app-MobileStepper' },
);
