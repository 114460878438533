import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import type { FeatureManagement } from '@amfament/digitalsales-lib-common-base/coreService';
import { useDisplayProgressStepper, useFeature } from '../../app/ContextAPI';
import MobileAppBar from './MobileAppBar';
import MobileAppBarNew from './MobileAppBarNew';
import Stepper from './Stepper';
import useStyles from './useStyles';
/**
 * This component is for showing the progress stepper
 */
const featureManagement: FeatureManagement = {
  key: 'horizontalmobileappheader-temp-1',
};

const ProgressStepper: React.FC = () => {
  const displayProgressStepper = useDisplayProgressStepper();
  const featureToggle = useFeature(featureManagement);
  const classes = useStyles();

  return displayProgressStepper ? (
    <>
      <Hidden lgDown>
        <Grid container>
          <Grid item lg={2} className={classes.root}>
            <Stepper />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        {/* Based of optimzely key the stepper orientation changes from veritical to horizontal for mobile */}
        {featureToggle.enabled ? (
          <MobileAppBarNew />
        ) : (
          <MobileAppBar>
            <Stepper />
          </MobileAppBar>
        )}
      </Hidden>
    </>
  ) : null;
};

export default ProgressStepper;
