import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      width: '305px',
      marginTop: '-15px',
      [theme.breakpoints.down('md')]: {
        marginTop: '0px',
      },
      boxShadow: '0 3px 3px rgba(0,0,0, .16)',
      borderTop: '1px solid #ADADAD',
      backgroundColor: theme.palette.background.default,
    },
    arrow: {
      width: '20px',
      height: '20px',
      borderLeft: '1px solid #ADADAD',
      borderTop: '1px solid #ADADAD',
      borderBottomRightRadius: '100%',
      transform: 'rotate(45deg)',
      position: 'absolute',
      background: theme.palette.background.default,
      top: '-10px',
      marginTop: '-15px',
      left: '89%',
      [theme.breakpoints.down('xl')]: {
        left: '87.5%',
      },
      [theme.breakpoints.down('md')]: {
        left: '89%',
        marginTop: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        left: '87.5%',
      },
    },
    grayArrow: {
      background: theme.palette.grey[100],
      width: '20px',
      height: '20px',
      borderLeft: '1px solid #ADADAD',
      borderTop: '1px solid #ADADAD',
      borderBottomRightRadius: '100%',
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-10px',
      marginTop: '-15px',
      left: '89%',
      [theme.breakpoints.down('xl')]: {
        left: '87.5%',
      },
      [theme.breakpoints.down('md')]: {
        left: '89%',
        marginTop: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        left: '87.5%',
      },
    },
  }),
  { name: 'ds-app-HelpMenu' },
);
