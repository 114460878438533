import Icon, { IconVariant } from '@amfament/digitalsales-lib-coreuicomps/Icon';
import Grid from '@mui/material/Grid';
import { useCallback } from 'react';
import useStyles from './useStyles';

export interface HelpMenuItemProps {
  menutItemKey: number;
  icon: IconVariant;
  text: string;
  link: string;
  linkRef: string;
  onMouseOver?: (item: number) => void;
}

/**
 * Component serves as an item of the help menu in upper right corner of application
 * @param menutItemKey: the key for specific menu item
 * @param icon: image associated with help item action
 * @param text: main text on an item
 * @param link: link text
 * @param linkRef: the target link when the menu item is clicked
 * @param onMouseOver: event for when mouse hovers over menu item
 */
const HelpMenuItem: React.FC<HelpMenuItemProps> = ({
  menutItemKey,
  icon,
  text,
  link,
  linkRef,
  onMouseOver,
}) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (linkRef) {
      window.open(linkRef);
    }
  }, [linkRef]);
  const handleMouseOver = useCallback(
    (): void => onMouseOver && onMouseOver(menutItemKey),
    [menutItemKey, onMouseOver],
  );

  return (
    <div
      className={classes.root}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onKeyPress={handleClick}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <Grid container>
        <Grid item xs={4} className={classes.helpIcon}>
          <Icon icon={icon} color="secondary" />
        </Grid>
        <Grid item xs={8} className={classes.text}>
          <div>{text}</div>
          <div className={classes.helpLink}>{link}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpMenuItem;
