import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import Grid from '@mui/material/Grid';
import type { ResourceConfig } from '../../app/types';
import useStyles from './useStyles';
import { content } from './content';

const { title, subTitle } = content;
declare let CONFIG: ResourceConfig;
const generalHardstopImage = `${CONFIG.image.baseURL}hardstop-general.png`;

/**
 *  The purpose of this component is to show the user the hardstop page when a hardstop situation occurs.
 */
const HardStop: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={12} md={8}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={6}>
            <img
              className={classes.image}
              src={generalHardstopImage}
              alt="Exception"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.titleContainer}>
              <DisplayText variant="h1" className={classes.title}>
                {title}
              </DisplayText>
              <DisplayText className={classes.subtitle}>{subTitle}</DisplayText>
            </div>
          </Grid>
        </Grid>
        <div className={classes.footerSeparation} />
      </Grid>
    </Grid>
  );
};

export default HardStop;
