import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import CoreStepper from '@mui/material/Stepper';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useProgressStepperFlowModules } from '../../../app/ContextAPI';
import StepIcon from './StepIcon';
import useStyles from './useStyles';

const DottedConnector = withStyles((theme) =>
  createStyles({
    active: {
      [`& .${stepConnectorClasses.line}`]: {},
    },
    completed: {
      [`& .${stepConnectorClasses.line}`]: {},
    },
    alternativeLabel: {
      left: 'calc(-50% + 6px)',
      right: 'calc(50% + 1px)',
      zIndex: '-1',
    },
    line: {
      paddingRight: '10px',
      borderStyle: 'dashed',
      borderWidth: '1px',
      borderRadius: '1px',
      fontWeight: 'bold',
      marginTop: '5px',
      borderColor: theme.palette.primary.main,
    },
  }),
)(StepConnector);

const MobileStepper: React.FC = () => {
  const flowModules = useProgressStepperFlowModules();
  const location = useLocation();
  const activeModule = flowModules.findIndex((module) =>
    module.routes.includes(location.pathname),
  );
  const classes = useStyles();

  const steps = useMemo(() => {
    return flowModules
      .map((module) => {
        // Filters the available pages to ensure they are enabled in the flow and not set to hidden.
        const displayablePages = module.pages.filter(
          (page) => page.enabled && !page.hidden,
        );
        if (displayablePages.length === 0) {
          return undefined;
        }

        // Create the step to be displayed under the module name in the stepper.
        const step = (
          <Step key={module.label}>
            <StepLabel
              StepIconComponent={StepIcon}
              className={classes.stepPage}
            />
          </Step>
        );
        return step;
      })
      .filter((step) => step);
  }, [classes.stepPage, flowModules]);

  return (
    <CoreStepper
      alternativeLabel
      activeStep={activeModule}
      connector={<DottedConnector />}
      className={classes.root}
      orientation="horizontal"
    >
      {steps}
    </CoreStepper>
  );
};

export default MobileStepper;
