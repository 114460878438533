import makeStyles from '@mui/styles/makeStyles';

export const size = 60;

export default makeStyles(
  (theme) => ({
    outerCircle: {
      marginLeft: `-${size}px`,
    },
  }),
  { name: 'ds-app-CircularProgress' },
);
