import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    appBarRoot: {
      minHeight: '64px',
    },
    root: {
      minHeight: '64px',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: theme.palette.grey[50],
      ...theme.typography.body2,
      color: theme.palette.text.primary,
      '@media print': {
        display: 'none',
      },
      zIndex: 3,
      top: 0,
    },
    toolbarContent: {
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        padding: '10px 22px 10px 20px',
        minHeight: '70px',
      },
    },
    drawer: {
      width: 275,
    },
  }),
  { name: 'ds-app-MobileAppBar' },
);
