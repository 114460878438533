import type { DefaultTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles<DefaultTheme, object, string>(
  // @ts-ignore - a weird error that doesn't make sense. Needed to add type args above as well. makeStyles<DefaultTheme, {}, string>
  // Types of property 'container' are incompatible.
  //   Type '{ height: string; }' is not assignable to type 'Container | PropsFunc<{}, Container | undefined> | undefined'.
  (theme) => ({
    root: {
      width: '100%',
      height: 'fit-content',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 2px 10px rgba(0,0,0,0.24)',
    },
    saveForLaterContainer: {
      paddingRight: '37px',
    },
    additionalLinksContainer: {
      marginRight: '30px',
      marginTop: '20px',
    },
    container: {
      height: '123px',
      width: '100%',
    },
    logo: {
      height: '50px',
      float: 'left',
      padding: '5px',
      margin: '35px 8px 35px 30px',
    },
    titleSection: {
      height: '37px',
      marginTop: '48px',
      borderLeft: '1px solid #717073',
      float: 'left',
      marginLeft: '1px',
    },
    title: {
      [theme.breakpoints.up('md')]: {
        lineHeight: 1,
      },
      marginTop: '5px',
      paddingLeft: '10px',
    },
    needHelpContainer: {
      cursor: 'pointer',
    },
    iconText: {
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: '10px',
      display: 'inline',
      lineHeight: 1,
    },
    iconContainer: {
      alignItems: 'center',
      display: 'inline-flex',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    helpMenu: {
      position: 'absolute',
      top: '120px',
      right: '1%',
      zIndex: theme.zIndex.tooltip,
    },
    [theme.breakpoints.down('md')]: {
      root: {
        height: '60px',
      },
      saveForLaterContainer: {
        paddingRight: '30px',
      },
      disappearIconText: {
        display: 'none',
      },
      logo: {
        height: '20px',
        padding: '0px',
        margin: '20px 20px 20px 16px',
      },
      container: {
        height: '60px',
      },
      helpMenu: {
        position: 'absolute',
        top: '60px',
        right: '1%',
        zIndex: theme.zIndex.tooltip,
      },
      title: {
        fontSize: '1rem',
      },
      additionalLinksContainer: {
        marginRight: '22px',
        marginTop: '10px',
      },
      needHelpContainer: {
        '@media print': {
          display: 'none',
        },
      },
    },
    banner: {
      backgroundColor: theme.palette.secondary.main,
      height: '2px',
      position: 'relative',
      zIndex: 0,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        zIndex: 1000,
      },
    },
  }),
  { name: 'ds-app-Header' },
);
