import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      height: '100vh',
    },
  }),
  { name: 'ds-app-Layout' },
);
