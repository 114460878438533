import makeStyles from '@mui/styles/makeStyles';
import { useFeature, usePageGradientDisplay } from '../../../app/ContextAPI';
import featureFlags from '../../../app/featureFlags';

export default () => {
  const { enabled: isBackgroundGradientEnabled } = useFeature({
    key: featureFlags.BACKGROUND_GRADIENT_ENHANCEMENT,
  });
  const enableBackgroundGradient = usePageGradientDisplay();
  return makeStyles(
    (theme) => {
      let stepPageNavigableLinkStyle = {
        color: theme.palette.primary[400],
      };

      if (enableBackgroundGradient && isBackgroundGradientEnabled) {
        stepPageNavigableLinkStyle = {
          color: theme.palette.primary.main,
        };
      }

      return {
        root: {
          background: 'transparent',
          [theme.breakpoints.down('lg')]: {
            padding: '32px 0px 24px 24px',
          },
          padding: '0px 0px 24px 24px',
        },
        stepContent: {
          borderLeftWidth: '2px',
          borderLeftColor: theme.palette.primary.main,
          borderLeftStyle: 'dashed',
          paddingLeft: '35px',
          marginLeft: '30px',
          marginTop: '0px',
        },
        endStepContent: {
          borderLeftWidth: '2px',
          borderLeftColor: theme.palette.primary.main,
          paddingLeft: '35px',
          marginLeft: '30px',
          marginTop: '0px',
        },
        stepPage: {
          color: theme.palette.text.secondary,
          padding: '0px',
        },
        stepPageLink: {
          textDecoration: 'none',
        },
        stepNavigableLink: {
          color: theme.palette.primary.main,
        },
        stepPageNavigableLink: stepPageNavigableLinkStyle,
        stepPageLinkActive: {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
      };
    },
    { name: 'ds-app-Stepper' },
  );
};
