import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      paddingTop: '15px',
      paddingBottom: '15px',
      marginLeft: '20px',
    },
    border: {
      borderLeft: '1px solid #ADADAD',
      borderRight: '1px solid #ADADAD',
      borderBottom: '1px solid #ADADAD',
    },
    title: {
      marginBottom: '10px',
    },
    container: {
      lineHeight: 1,
    },
    value: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '20px',
    },
  }),
  { name: 'ds-app-ProductQuoteIds' },
);
