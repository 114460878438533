import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import MenuIcon from '@mui/icons-material/Menu';
import type { Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  findNextPageLabel,
  useHeaderTitle,
  useProgressStepperFlowModules,
} from '../../../app/ContextAPI';
import useStyles from './useStyles';

const MobileAppBar: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // Boolean Used to detect between Small Screens (ie. Phones) and Medium Screens (ie. Tablets)
  const isSmallScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md'),
  );
  const [shouldFix, setShouldFix] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShouldFix(window.scrollY > (isSmallScreen ? 60 : 123));
    });
  }, [isSmallScreen]);
  const classes = useStyles();

  const flowModules = useProgressStepperFlowModules();
  const location = useLocation();
  const nextPageLabel = findNextPageLabel(flowModules, location);
  const headerTitle = useHeaderTitle();

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleDrawerClose = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  return (
    <Grid item xs={12} className={classes.appBarRoot}>
      <AppBar
        position={shouldFix ? 'fixed' : 'relative'}
        className={classes.root}
      >
        <Toolbar className={classes.toolbarContent}>
          <Grid>
            <Hidden lgUp>
              <DisplayText noWrap>{headerTitle}</DisplayText>
            </Hidden>
            <DisplayText variant="caption" fontWeight="medium" noWrap>
              {nextPageLabel ? `UP NEXT: ${nextPageLabel}` : ''}
            </DisplayText>
          </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={handleDrawerClose}>
        <div
          className={classes.drawer}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          {children}
        </div>
      </Drawer>
    </Grid>
  );
};

export default MobileAppBar;
