import makeStyles from '@mui/styles/makeStyles';
import { useFeature, usePageGradientDisplay } from '../../../app/ContextAPI';
import featureFlags from '../../../app/featureFlags';

export default () => {
  const { enabled: isBackgroundGradientEnabled } = useFeature({
    key: featureFlags.BACKGROUND_GRADIENT_ENHANCEMENT,
  });
  const enableBackgroundGradient = usePageGradientDisplay();
  const gradientBackground =
    enableBackgroundGradient && isBackgroundGradientEnabled;
  return makeStyles(
    (theme) => ({
      appBarRoot: {
        minHeight: '64px',
        overflow: 'hidden',
        background: gradientBackground
          ? theme.palette.primary[100]
          : theme.palette.background.altNeutral,
        borderBottom: gradientBackground
          ? 'none'
          : `1px solid ${theme.palette.divider}`,
        width: '100%',
      },
      root: {
        minHeight: '64px',
        background: 'transparent',
        boxShadow: 'none',
        '@media print': {
          display: 'none',
        },
        zIndex: 3,
        top: 0,
        width: '100%',
      },
      currentStep: {
        color: theme.palette.primary.main,
        paddingBottom: '12px',
        overflowWrap: 'break-word',
        maxWidth: '90%',
      },
      pageName: {
        fontWeight: theme.typography.fontWeightRegular,
      },
    }),
    { name: 'ds-app-MobileAppBarNew' },
  );
};
