import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
      bottom: 0,
      '@media print': {
        display: 'none',
      },
    },
    containerColor: {
      borderTop: `2px solid ${theme.palette.grey[200]}`,
      width: '100%',
      backgroundColor: theme.palette.grey[100],
    },
    container: {
      padding: '30px',
      margin: 'auto',
      maxWidth: '768px',
      minWidth: '40%',
    },
    containerWide: {
      padding: '30px',
      margin: 'auto',
      width: '100%',
      maxWidth: '1056px',
    },
    copyright: {
      fontSize: '0.75rem',
      color: '#717073',
      textAlign: 'center',
      marginTop: '10px',
    },
    mobileSpacing: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: '1px',
      },
    },
    [theme.breakpoints.down('md')]: {
      link: {
        fontSize: '0.8125rem',
      },
      copyright: {
        fontSize: '0.6875rem',
      },
    },
  }),
  { name: 'ds-app-Footer' },
);
