import makeStyles from '@mui/styles/makeStyles';
import { useFeature, usePageGradientDisplay } from '../../../../app/ContextAPI';
import featureFlags from '../../../../app/featureFlags';

export default () => {
  const { enabled: isBackgroundGradientEnabled } = useFeature({
    key: featureFlags.BACKGROUND_GRADIENT_ENHANCEMENT,
  });
  const enableBackgroundGradient = usePageGradientDisplay();
  const gradientBackground =
    enableBackgroundGradient && isBackgroundGradientEnabled;

  return makeStyles(
    (theme) => ({
      stepIcon: {
        marginLeft: '15px',
        marginRight: '15px',
        height: '36px',
        marginBottom: '3px',
        zIndex: 14,
        background: gradientBackground
          ? theme.palette.primary[100]
          : theme.palette.grey[50],
      },
    }),
    { name: 'ds-app-StepIcon' },
  );
};
