import { useLoadingStateProcess } from '../../app/ContextAPI';
import DefaultSpinner from './DefaultSpinner';

export interface LoadingSpinnerProps {
  message?: React.ReactNode;
  isLoading?: boolean;
}

/**
 * The purpose of this component is to act as a progress symbol for whenever
 * the application is either making async calls or when it is initially starting up
 * @param message: Message displayed on the loading spinner
 * @param isLoading: This determines whether or not the spinner is displayed.
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  message: messageProp,
  isLoading: isLoadingProp,
}) => {
  const currentProcess = useLoadingStateProcess();
  const isLoading = currentProcess ? true : isLoadingProp;
  const message = currentProcess ? currentProcess.message : messageProp;

  return <DefaultSpinner message={message} isLoading={isLoading} />;
};

export default LoadingSpinner;
